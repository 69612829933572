import { FINANCE_ROLE } from "../config";

export const readOnlyRole ="readonly.masterdata.all.apps "
export const superAdminRole = "stg-superadmin.finance.masterdata.all.app";

var roles = [];

export function setRoles(rolesFromJWT) {
    if (typeof rolesFromJWT === 'string') {
        roles = rolesFromJWT.split(',');
    } else if (Array.isArray(rolesFromJWT)) {
        roles = rolesFromJWT.slice();
    }
};

export function checkIfRoleExists(roleName) {
    var toReturn = roles.find((role) => { return role.trim() === roleName });

    return Boolean(toReturn);
};

export const financeRole = FINANCE_ROLE;
