import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const UserContext = createContext({
  email: "",
  role: [],
  isAuthorized: false,
  lead: "",
  isLead: false,
  isAdmin: false
})


export const withUserContext = (Component) => (props) => {
  return (
    <UserContext.Consumer>
      {(context) => <Component {...props} {...context} />}
    </UserContext.Consumer>
  );
}


export const useUserContext = () => {
  return useContext(UserContext);
}


export const UserContextProvider = (props) => {
  const { children, context } = props;

  return (
    <UserContext.Provider value={context}>
      {children}
    </UserContext.Provider>
  )
}

UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  context: PropTypes.exact({
    email: PropTypes.string.isRequired,
    role: PropTypes.array,
    lead: PropTypes.string,
    isAuthorized: PropTypes.bool.isRequired,
    isLead: PropTypes.bool.isRequired,
    setLead: PropTypes.func,
    setIsLead: PropTypes.func,
    isAdmin: PropTypes.bool.isRequired
  })
}


export default UserContext;
