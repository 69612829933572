import React from 'react';

const Forbidden = () => {
    return (
        <div className="Forbidden">
            <h1><b>Error 404</b></h1>
            <h2>Page Not Found</h2>
        </div>
    );
}

export default Forbidden;
