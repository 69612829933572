import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Avatar,
  IconButton,
  Toolbar,
  Typography,
  Box,
  Menu,
  MenuList,
  MenuItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  ListItemIcon
} from '@mui/material';
import { useUserContext } from '../contexts/UserContext';
import headerAppData from '../contexts/headerAppData';
import TabsHeader from '../components/elements/TabsHeader';
import logo from '../images/WSO2_Logo.png';
import PersonIcon from '@mui/icons-material/Person';
import { PowerSettingsNew } from '@mui/icons-material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  checkIfRoleExists,
  financeRole,
  superAdminRole
} from '../containers/Roles';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1
  },
  logo: {
    maxWidth: '100%'
  },
  logoLink: {
    maxWidth: '75px',
    paddingRight: '1%',
    textDecoration: 'none',
    marginTop: 5
  },
  loggedUserPanel: {
    display: 'inline-flex',
    marginTop: '1%',
    marginBottom: '1%',
    color: '#e8e6ef'
  },
  userIcon: {
    margin: 'auto 0',
    paddingRight: 10
  },
  userEmail: {
    margin: 'auto 0',
    paddingRight: 10,
    fontWeight: '500'
  },
  guideButton: {
    padding: '0 0 0 10px'
  },
  cogwheelIcon: {
    marginTop: 6.5,
    marginRight: 15,
    color: '#e8e6ef'
  },
  root: {
    '& .MuiAppBar-colorPrimary': {
      color: '#fff',
      background: '#212a32'
    }
  },
  menuListProfile: {
    width: '100%',
    height: '30px',
    padding: '25px !important'
  },
  menuList: {
    width: '100%',
    height: '20px',
    paddingLeft: '25px !important',
    paddingTop: '20px !important',
    paddingBottom: '20px !important'
  }
}));

const Header = (props) => {
  const { email } = useUserContext();
  const location = useLocation();
  const classes = useStyles();

  const [tabData, setTabData] = useState(null);
  const [accountAnchorEl, setAccountAnchorEl] = useState(null);
  const openAccount = Boolean(accountAnchorEl);

  useEffect(() => {
    // Split the current URL at '/' into an array, and get the current App user is logged in
    const curPathArr = location.pathname.split('/');
    const currentApp = curPathArr[1]; // returns '', 'opd', 'cc' or 'exp'

    // Data object containing data that is to be included in the title and toolbar navigation (header) buttons on each app
    // Assign the data/props of current app to 'tabData' state
    setTabData(headerAppData[currentApp]);
  }, [location]);

  const guideLink = () => {
    const curPath = location.pathname;

    switch (curPath) {
      case '/opd':
        return 'https://docs.google.com/document/d/1BEuie_0wVVf18MjsZjrEb6_z-0fBDCxhryYA2T1GJnA/edit?usp=sharing';
      case '/cc':
        return 'https://docs.google.com/document/d/1xozfIr7kR96x3jb2RGEP6dLzGdfCXlkhXXfXkZJWxqQ/edit?usp=sharing';
      case '/exp':
        return 'https://docs.google.com/document/d/1BaAbR-kPh6WiC8vT3Gz4e0aFXA_aHIdPdQOqB7aASTQ/edit?usp=sharing';
      default:
        return null;
    }
  };

  const handleClickAccount = (event) => {
    setAccountAnchorEl(event.currentTarget);
  };
  const handleCloseAccount = () => {
    setAccountAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="sticky">
          <Toolbar>
            <Link to="/" className={classes.logoLink}>
              <img className={classes.logo} src={logo} alt="logo" />
            </Link>
            <Typography className={classes.grow} variant="h6" color="inherit">
              {tabData ? tabData.title : 'Expense Management App'}
            </Typography>
            <Box />
            <Box sx={{ display: 'flex' }} alignItems={'center'}>
              <Typography
                sx={{ marginRight: '15px !important' }}
                variant="body1"
                display="block"
              >
                {email}
              </Typography>

              <IconButton
                onClick={handleClickAccount}
                size="small"
                sx={{ ml: 3 }}
              >
                <Avatar alt={email}>
                  <PersonIcon />
                </Avatar>
              </IconButton>

              <Menu
                style={{ zIndex: 10001 }}
                anchorEl={accountAnchorEl}
                open={openAccount}
                onClose={handleCloseAccount}
                onClick={handleCloseAccount}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    width: 300,
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0
                    }
                  }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuList>
                  <MenuItem className={classes.menuListProfile}>
                    <ListItemAvatar>
                      <Avatar>
                        <PersonIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={email} />
                  </MenuItem>
                  <Divider />
                  {guideLink() && (
                    <MenuItem
                      onClick={() => window.open(guideLink(), '_blank')}
                      className={classes.menuList}
                    >
                      <ListItemIcon>
                        <HelpOutlineIcon
                          color="primary.main"
                          fontSize="small"
                        />
                      </ListItemIcon>
                      <ListItemText primary="Help" />
                    </MenuItem>
                  )}

                  <MenuItem
                    onClick={props.signOut}
                    className={classes.menuList}
                  >
                    <ListItemIcon>
                      <PowerSettingsNew color="primary.main" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </Toolbar>
          {
            // Following will check whether 'data' object is assigned with an 'AppData'
            // object or not and render the tabs section.
            // i.e. user is currently in one of the app pages
            tabData && <TabsHeader {...tabData} />
          }
        </AppBar>
      </Box>
    </div>
  );
};

export default Header;
