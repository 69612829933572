import React, { Suspense, lazy } from 'react'
import { Route, Switch } from 'react-router-dom';
import LeadRoute from './components/LeadRoute';
import AdminRoute from './components/AdminRoute';
import Loader from './components/elements/Loader';

// Other pages
import Forbidden from './containers/Forbidden';
import NotFound from './containers/NotFound';

// Components are lazy loaded on the client side as they are rendered
const CCContextProvider = lazy(async () => {
  const Component = await import('./contexts/AppCCContext');
  return ({ default: Component.CCContextProvider });
});
// Home component
  const Home = lazy(() => import('./containers/Home'));
// OPD Claim app components
// TEMP DISABLED since OPD Claims are on hold (search TEMP DISABLED to view all disabled locations)
  const OPDNewClaim = lazy(() => import('./containers/opd/UserNewOPDClaim'));
  const OPDClaimHistory = lazy(() => import('./containers/opd/UserOPDClaimHistory'));
  const OPDAllClaims = lazy(() => import('./containers/opd/AdminAllOPDClaims'));
  const OPDSettings = lazy(() => import('./containers/opd/AdminSettings'));
// Expense Claimn app components
  const ExpenseNewClaim = lazy(() => import('./containers/exp/NewExpenseClaim'));
  const ExpenseClaimHistory = lazy(() => import('./containers/exp/UserExpenseClaimsHistory'));
  const LeadApprovalSettings = lazy(() => import('./containers/exp/LeadApprovalSettings'));
  const FinanceApprovalSettings = lazy(() => import('./containers/exp/FinanceApprovalSettings'));
// Credit Card Claim app Routes
  const CCNewSubmission = lazy(() => import('./containers/cc/UserNewCCSubmision'));
  const CCPendingSubmissions = lazy(() => import('./containers/cc/UserPendingCCSubmissions'));
  const CCSubmissionHistory = lazy(() => import('./containers/cc/UserCCSubmissionHistory'));
  const CCApproveSubmissions = lazy(() => import('./containers/cc/LeadApproveCCSubmissions'));
  const CCSettings = lazy(() => import('./containers/cc/AdminCCSettings'));
// Salary/Payment credit account app Routes
  const AddBankDetails = lazy(()=> import('./containers/acc/NewBankDetails'));
  const AllReimAccChange = lazy(()=> import('./containers/acc/ReimbursementAccChanges'));

// Master Data app Routes
  // const DataGridDemo = lazy(() => import('./components/masterdata/TableList'));
  // const ECtable = lazy(() => import('./components/masterdata/TableListEC'));
  // const ETtable = lazy(() => import('./components/masterdata/TableListET'));
  // const CCtable = lazy(() => import('./components/masterdata/TableListCC'));
  // const Pdtable = lazy(() => import('./components/masterdata/TableListPd'));
  // const Subtable = lazy(() => import('./components/masterdata/TableListSub'));
  // const TaxCodesTable = lazy(() => import('./components/masterdata/TableListTaxCodes'));
  // const BusinessUnitsTable = lazy(() => import('./components/masterdata/TableListBu'));
  // const ProductionUnits = lazy(() => import('./components/masterdata/TableListPu'));

const MainRoutes = (props) => {
  return (
    <Suspense fallback={<Loader message="Page Loading..." />}>
      <Switch>
        <Route exact path="/" component={Home} />

        {/* OPD app routes */}
        {/* TEMP DISABLED since OPD Claims are on hold (search TEMP DISABLED to view all disabled locations) */}
        <Route exact path="/opd" component={OPDNewClaim} />
        <Route exact path="/opd/claim-history" component={OPDClaimHistory} />
        <AdminRoute exact path="/opd/all-claim-history" component={OPDAllClaims} />
        <AdminRoute exact path="/opd/settings" component={OPDSettings} />

        {/* Expense Claim app routes */}
        <Route exact path="/exp" component={ExpenseNewClaim} />
        <Route exact path="/exp/claim-history" component={ExpenseClaimHistory} />
        <Route exact path="/exp/lead-approval-settings" component={LeadApprovalSettings} />
        <Route exact path="/exp/finance-approval-settings" component={FinanceApprovalSettings} />

         {/* Salary/Payment credit account app Routes */}
         <LeadRoute exact path="/acc/add-new-bank-details" component={AddBankDetails} />
         <LeadRoute exact path="/acc" component={AllReimAccChange} />

        {/* Credit Card app routes */}
        <CCContextProvider>
          <Route exact path="/cc" component={CCNewSubmission} />
          <Route exact path="/cc/claims-pending" component={CCPendingSubmissions} />
          <Route exact path="/cc/claim-history" component={CCSubmissionHistory} />
          <LeadRoute exact path="/cc/claims-approve" component={CCApproveSubmissions} />
          <AdminRoute exact path="/cc/settings" component={CCSettings} />
        </CCContextProvider>

        {/* Master Data app routes */}
        {/* <Route exact path="/masterdata" component={DataGridDemo} />
        <Route exact path="/masterdata/expensecategory" component={ECtable} />
        <Route exact path="/masterdata/et" component={ETtable} /> 
        <Route exact path="/masterdata/cc" component={CCtable} />
        <Route exact path="/masterdata/pd" component={Pdtable} />
        <Route exact path="/masterdata/subsidiaries" component={Subtable} />
        <Route exact path="/masterdata/tc" component={TaxCodesTable} />
        <Route exact path="/masterdata/bu" component={BusinessUnitsTable} />
        <Route exact path="/masterdata/pu" component={ProductionUnits} /> */}

        {/* Redirect route for Users on accesssing Admin pages */}
        <Route exact path="/forbidden" component={Forbidden} />
        {/* TEMP DISABLED since OPD Claims are on hold (search TEMP DISABLED to view all disabled locations) */}
        {/* <Route path="/opd" component={NotFound} /> */}
        <Route exact path="/1.0" component={Home} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default MainRoutes;
