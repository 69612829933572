import { useState, useEffect, Fragment } from 'react';
import { BrowserRouter, useHistory } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@mui/material';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { APP_NAME, ADMIN_ROLE, APP_DESCRIPTION } from './config';
import { UserContextProvider } from './contexts/UserContext';
import { useAuthContext } from '@asgardeo/auth-react';
import MainRoutes from './MainRoutes';
import Header from './containers/Header';
import { Helmet } from 'react-helmet';
import Loader from './components/elements/Loader';
import { setRoles } from './containers/Roles';
import { CssBaseline } from '@material-ui/core';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  setIdToken,
  // getNewTokens,
  // revokeTokens,
  setRefreshTokenFunction,
  setSessionClearFunction,
  setAccessToken
} from './utils/oauth2';
// import MasterDataHeader from './containers/MasterDataHeader';

import PRODUCT_LOGOS from './images/ema-app-login-logos.png';
import BACKGROUND_IMAGE from './images/ema-app-login-background.png';
import { Stack } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#212a32'
    },
    secondary: {
      main: '#ff7300'
    }
  }
});

const App = () => {
  const history = useHistory();
  const {
    state,
    signIn,
    signOut,
    getBasicUserInfo,
    getIDToken,
    getAccessToken,
    getDecodedIDToken,
    refreshAccessToken,
    revokeAccessToken
  } = useAuthContext();
  const [loggedOutStatus, setLoggedOutStatus] = useState('');
  const [authenticateState, setAuthenticateState] = useState(null);
  const [isAppInitializing, setIsAppInitializing] = useState(false);
  const [email, setEmail] = useState('');
  const [role, setRole] = useState([]);
  const [lead, setLead] = useState('');
  const [isAuthorized, setAuthorized] = useState(false);
  const [isLead, setIsLead] = useState(false);
  const [isAdmin, setAdmin] = useState(false);
  const [loadApp, setLoadApp] = useState(false);

  const setIsLoggedOut = (value) => {
    sessionStorage.setItem('isLoggedOut', value);

    setLoggedOutState(value === 'true');
  };

  const getIsLoggedOut = () => {
    if (sessionStorage.getItem('isLoggedOut') === 'true') {
      return true;
    } else {
      return false;
    }
  };
  const [loggedOutState, setLoggedOutState] = useState(getIsLoggedOut());

  const setIsSessionTimeOut = (value) => {
    sessionStorage.setItem('isSessionTimeOut', value);
  };

  const sessionClearFn = () => {
    setLoadApp(false);
    setIsInitLogin('false');
    setIsSessionTimeOut('true');
    setIsLoggedOut('true');

    // revokeTokens();
    revokeAccessToken().catch((e) => {
      console.error(e);
    });
    history && history.push('');
  };

  // TODO
  // useEffect(() => {
  //   if (!getIsLoggedOut() && !(state?.isLoading || state?.isAuthenticated)) {
  //     handleLogin();
  //   }
  // }, [state.isLoading, state.isAuthenticated]);

  useEffect(() => {
    if (getIsInitLogin()) {
      signIn().catch((error) => {
        setLoggedOutStatus('Error while logging in!');
      });
    }
  }, []);

  useEffect(() => {
    if (state?.isAuthenticated) {
      setRefreshTokenFunction(handleRefreshToken);
      setSessionClearFunction(sessionClearFn);
      const getData = async (callback) => {
        const basicUserInfo = await getBasicUserInfo();
        const accessToken = await getAccessToken();
        const idToken = await getIDToken();
        const decodedIDToken = await getDecodedIDToken();
        const authState = {
          authenticateResponse: basicUserInfo,
          idToken: idToken.split('.'),
          decodedIdTokenHeader: JSON.parse(atob(idToken.split('.')[0])),
          decodedIDTokenPayload: decodedIDToken
        };
        setAccessToken(accessToken);
        setIdToken(idToken);
        // if (idToken) {
        //   getNewTokens(() => {
        //     setLoadApp(true);

        //     // if (callback) {
        //     //   callback();
        //     // }
        //   }).catch((e) => {
        //     sessionClearFn();
        //     console.error(e);
        //   });
        // }
        if (accessToken) {
          setLoadApp(true);
          callback?.();
        }

        //TO DO CHANGE
        let userEmail = basicUserInfo.email;
        let userRole = basicUserInfo.groups;

        if (userEmail !== undefined && userRole !== undefined) {
          setEmail(userEmail);
          setRole(userRole);
          setRoles(userRole);
          setAuthorized(true);
          userRole.forEach((role) => {
            if (role === ADMIN_ROLE) {
              setAdmin(true);
              return;
            }
          });
        }
        setAuthenticateState(authState);
      };
      getData();
    }
  }, [state.isAuthenticated]);

  // useEffect(() => {
  //   if (state?.isAuthenticated) {
  //     // setRefreshTokenFunction(handleRefreshToken);
  //     const getData = async () => {
  //       const basicUserInfo = await getBasicUserInfo();
  //       const idToken = await getIDToken();
  //       const decodedIDToken = await getDecodedIDToken();
  //       const authState = {
  //         authenticateResponse: basicUserInfo,
  //         idToken: idToken.split('.'),
  //         decodedIdTokenHeader: JSON.parse(atob(idToken.split('.')[0])),
  //         decodedIDTokenPayload: decodedIDToken
  //       };

  //       setIdToken(idToken);
  //       // if(idToken) {
  //       //   if(!OAUTH_CONFIG.SKIP_TOKEN_EXCHANGE){
  //       //     getNewTokens();
  //       //   }
  //       // }

  //       //TO DO CHANGE
  //       let userEmail = 'chanuka@wso2.com';
  //       let userRole = basicUserInfo.groups;

  //       if (userEmail !== undefined && userRole !== undefined) {
  //         setEmail(userEmail);
  //         setRole(userRole);
  //         setRoles(userRole);
  //         setAuthorized(true);
  //         userRole.forEach((role) => {
  //           if (role == ADMIN_ROLE) {
  //             setAdmin(true);
  //           }
  //         });
  //       }
  //       setAuthenticateState(authState);
  //     };
  //     getData();
  //   } else {
  //     if (!getToken()) {
  //       setIdToken(handleRefreshToken());
  //     }
  //   }
  // }, []);

  const getIsInitLogin = () => {
    if (sessionStorage.getItem('isInitLogin') === 'true') {
      return true;
    } else {
      return false;
    }
  };

  const setIsInitLogin = (value) => {
    sessionStorage.setItem('isInitLogin', value);
  };

  const handleLogin = () => {
    setIsInitLogin('true');
    setIsSessionTimeOut('false');
    setIsLoggedOut('false');

    signIn().catch((e) => {
      console.error(e);
    });
  };

  // const handleLogin = () => {
  //   signIn();
  //   setIsInitLogin('true');
  // };

  // const handleLogout = () => {
  //   signOut();
  //   setIsInitLogin('false');
  // };

  // const handleLogout = () => {
  //   setIsAppInitializing(true);
  //   // revoke token is not working for choreo
  //   revokeTokens(() => {
  //     signOut().catch((e) => {
  //       console.error(e);
  //     });
  //     setIsInitLogin("false");
  //     setIsLoggedOut("true");
  //     setIsSessionTimeOut("false");
  //     setIsAppInitializing(false);
  //   });
  // };

  const handleLogout = () => {
    setIsAppInitializing(true);
    setIsInitLogin('false');
    setIsLoggedOut('true');
    setIsSessionTimeOut('false');
    setIsAppInitializing(false);

    revokeAccessToken().then(signOut());
  };

  const handleRefreshToken = async () => {
    return refreshAccessToken()
      .then(async (e) => {
        setAccessToken(await getAccessToken());
      })
      .catch((err) => {
        if (err) {
          handleLogout();
          throw err;
        }
      });
  };

  const userContext = {
    email,
    role,
    lead,
    isAuthorized,
    isLead,
    isAdmin,
    setLead,
    setIsLead
  };

  const basename = {
    local: '/', // Basename for local env
    stag: '/t/wso2internalstg/expenseclaimstg', // Basename for staging env
    prod: '/t/wso2internal928/expenseclaim' // Basename for production env
  };

  return (
    <Fragment>
      {state.isAuthenticated && loadApp ? (
        <BrowserRouter basename={basename.local}>
          <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
              <UserContextProvider context={userContext}>
                {/* {window.location.pathname.includes('masterdata') ? (<MasterDataHeader/>) : (<Header signOut={handleLogout} />)} */}
                <CssBaseline>
                  <Header signOut={handleLogout} />
                </CssBaseline>
                <MainRoutes />
              </UserContextProvider>
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </BrowserRouter>
      ) : getIsInitLogin() ? (
        <Grid
          style={{
            width: '100vw',
            height: '100vh',
            display: 'table-cell',
            textAlign: 'center',
            verticalAlign: 'middle'
          }}
          item
          xs={12}
        >
          <Loader message={'Loading'} />
        </Grid>
      ) : (
        <>
          <Helmet>
            <title>Login | {APP_NAME}</title>
          </Helmet>
          <ThemeProvider theme={theme}>
            <Box
              sx={{
                backgroundColor: 'background.default',
                display: 'flex',
                flexDirection: 'column',
                width: '100vw',
                height: '100vh',
                justifyContent: 'center',
                backgroundImage: `url(${BACKGROUND_IMAGE})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
              }}
            >
              <Container fixed maxWidth="xs">
                <Card
                  elevation={24}
                  sx={{
                    borderRadius: 3,
                    pt: 3,
                    mx: 2
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        px: 3
                      }}
                    >
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        p={1}
                      >
                        <Grid item xs={12}>
                          <img
                            alt="logo"
                            width="130"
                            height="auto"
                            src="https://wso2.cachefly.net/wso2/sites/images/brand/downloads/wso2-logo.png"
                          ></img>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            align="center"
                            sx={{ fontWeight: 'bold' }}
                            variant="h5"
                          >
                            {APP_NAME}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ pb: 2 }}>
                          <Typography align="center" sx={{ fontSize: '1em' }}>
                            {APP_DESCRIPTION}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <LoadingButton
                            id="login"
                            onClick={() => {
                              handleLogin();
                            }}
                            variant="contained"
                            color="secondary"
                            loading={getIsInitLogin()}
                            loadingPosition="center"
                            sx={{ color: 'white' }}
                          >
                            Log In
                          </LoadingButton>
                        </Grid>
                        {getIsInitLogin() && (
                          <Grid item xs={12}>
                            <Typography variant="caption">
                              Redirecting to Asgardeo...
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs={12} mt={10}>
                          <Stack direction="column" spacing={2}>
                            <Typography align="center">Powered By</Typography>

                            <Stack direction="row" spacing={2}>
                              <img height={22} src={PRODUCT_LOGOS} />
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} mt={3}>
                          <Typography
                            align="center"
                            color="text.secondary"
                            sx={{ fontSize: '0.8em' }}
                          >
                            {'© 2023 WSO2 LLC'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                  <Divider />
                </Card>
              </Container>
            </Box>
          </ThemeProvider>
        </>
      )}
    </Fragment>
  );
};

export default App;
