import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useUserContext } from '../contexts/UserContext';

const LeadRoute = ({ component: Comp, ...rest }) => {
  const { isLead, isAdmin } = useUserContext();

  return (
    <Route {...rest} render={(props) => ((isLead || isAdmin)
      ? <Comp {...props} />
      : <Redirect to="/forbidden" />
    )} />
  );
}

export default LeadRoute;