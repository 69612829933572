import React from 'react';
import {
  Add as AddIcon,
  History as HistoryIcon,
  AccessTime as PendingIcon,
  InsertDriveFile as FileIcon,
  SettingsApplications as SettingsIcon
} from "@material-ui/icons";

// Data that is to be included in the title and toolbar navigation (header) buttons on each app
const headerAppData = {
  acc:{
    title: "Reimbursement Account Info",
    tabs: [
      {
        label: "Reimbursement Account Changes",
        path: "/acc",
        icon: <HistoryIcon />,
        onlyLead: true,
        onlyAdmin: true
      },
      {
        label: "Add New Bank Details",
        path: "/acc/add-new-bank-details",
        icon: <AddIcon />,
        onlyLead: true,
        onlyAdmin: true
      },
    ]
  },
  opd: {
    title: "OPD Claims",
    tabs: [
      {
        label: "New Claim",
        path: "/opd",
        icon: <AddIcon />,
        onlyLead: false,
        onlyAdmin: false
      },
      {
        label: "Claim History",
        path: "/opd/claim-history",
        icon: <HistoryIcon />,
        onlyLead: false,
        onlyAdmin: false
      },
      {
        label: "All OPD Claims",
        path: "/opd/all-claim-history",
        icon: <FileIcon />,
        onlyLead: false,
        onlyAdmin: true
      },
      // {
      //   label: "Settings",
      //   path: "/opd/settings",
      //   icon: <SettingsIcon />,
      //   onlyLead: false,
      //   onlyAdmin: true
      // }
    ]
  },
  cc: {
    title: "Credit Card Expenses",
    tabs: [
      {
        label: "New",
        path: "/cc",
        icon: <AddIcon />,
        onlyLead: false,
        onlyAdmin: false
      },
      {
        label: "Pending",
        path: "/cc/claims-pending",
        icon: <PendingIcon />,
        onlyLead: false,
        onlyAdmin: false
      },
      {
        label: "Approve",
        path: "/cc/claims-approve",
        icon: <PendingIcon />,
        onlyLead: true,
        onlyAdmin: true
      },
      {
        label: "History",
        path: "/cc/claim-history",
        icon: <HistoryIcon />,
        onlyLead: false,
        onlyAdmin: false
      },
      {
        label: "Settings",
        path: "/cc/settings",
        icon: <SettingsIcon />,
        onlyLead: false,
        onlyAdmin: true
      }
    ]
  },
  exp: {
    title: "Expense Claims",
    tabs: [
      {
        label: "New",
        path: "/exp",
        icon: <AddIcon />,
        onlyLead: false,
        onlyAdmin: false
      },
      {
        label: "History",
        path: "/exp/claim-history",
        icon: <HistoryIcon />,
        onlyLead: false,
        onlyAdmin: false
      },
      {
        label: "Approve(Lead)",
        path: "/exp/lead-approval-settings",
        icon: <SettingsIcon />,
        onlyLead: true,
        onlyAdmin: false
      },
      {
        label: "Approvals(Finance)",
        path: "/exp/finance-approval-settings",
        icon: <SettingsIcon />,
        onlyLead: false,
        onlyAdmin: true
      }
    ]
  }
}

export default headerAppData;
