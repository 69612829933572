import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useUserContext } from '../contexts/UserContext';

const AdminRoute = ({ component: Comp, ...rest }) => {
  const { isAdmin } = useUserContext();

  return (
    <Route {...rest} render={(props) => ((isAdmin)
      ? <Comp {...props} />
      : <Redirect to="/forbidden" />
    )} />
  );
}

export default AdminRoute;