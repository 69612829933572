import React from 'react';

const Forbidden = () => {
    return (
        <div className="Forbidden">
            <h1>Error 403</h1>
            <h2>Access Forbidden</h2>
        </div>
    );
}

export default Forbidden;
