// config values related to accessing java servlet

export const AppConfig = {
  baseUrl: '',
  opdbaseUrl: window.config?.REACT_APP_OPD_BASE_URL ?? '',
  expbaseUrl: window.config?.REACT_APP_EXP_BASE_URL ?? '',
  ccBaseUrl: window.config?.REACT_APP_CC_BASE_URL ?? '',
  bankingAppBaseUrl: '',
  expAppUrl: window.config?.REACT_APP_EXP_APP_URL ?? '',

  userPath: 'user/',
  userProfileEndpoint: 'profile/',

  //Opd Claims endpoints
  claimsPath: 'opdClaimService/',
  newClaimEndpoint: 'claims',
  getClaimsEndpoint: 'claims',
  getAllClaimsEndpoint: 'claims',
  updateClaimEndpoint: 'updateClaim',
  settingsPath: 'settings/',

  //admin endpoints
  getAllProjectsEndpoint: 'getProjects',
  adminRole: 'admin',

  //ET CRUD endpoints
  allETEndpoint: 'expense-types/',
  newETEndpoint: 'expense-types/',
  updateETEndpoint: 'expense-types/',
  deleteETEndpoint: 'expense-types/',

  //Subsidiaries/Sub CRUD endpoints
  allSubEndpoint: 'subsidiaries/',
  newSubEndpoint: 'subsidiaries/',
  updateSubEndpoint: 'subsidiaries/',
  deleteSubEndpoint: 'subsidiaries/',

  //Ec CRUD endpoints
  allECEndpoint: 'expense-categories/',
  newECEndpoint: 'expense-categories/',
  updateECEndpoint: 'expense-categories/',
  deleteECEndpoint: 'expense-categories/',

  //cc CRUD endpoints
  allCCEndpoint: 'credit-cards/',
  newCCEndpoint: 'credit-cards/',
  updateCCEndpoint: 'credit-cards/',
  deleteCCEndpoint: 'credit-cards/',

  allPDEndpoint: 'projects/',
  newPDEndpoint: 'projects/',
  deletePDEndpoint: 'projects/',
  updatePDEndpoint: 'projects/',
  allGlCodesEndpoint: 'general-ledger-codes/',

  //Tc CRUD endpoints
  allTCEndpoint: 'tax-codes/',
  newTCEndpoint: 'tax-codes/',
  updateTCEndpoint: 'tax-codes/',
  deleteTCEndpoint: 'tax-codes/',

  //gl CRUD endpoints
  allGlEndpoint: 'general-ledgers/',
  newGlEndpoint: 'general-ledgers/',
  updateGlEndpoint: 'general-ledgers/',
  deleteGlEndpoint: 'general-ledgers/',

  //Bu endpoints
  allBuEndpoint: 'business-units/',

  //Pu endpoints
  allPuEndpoint: 'product-units/',

  //Expesne Claim endpoints
  expenseClaimsPath: 'expense-claims/',
  newExpenseClaimEndpoint: 'claims/',
  updateExpenseClaimEndpoint: 'claims/',
  getEmployeeExpeseClaimsByEmail: 'claims/',
  getClaimsTobeApprovedByLead: 'claims/',
  getClaimsTobeApprovedByFinanceApprover: 'claims/',
  getClaimsApprovedByFinanceApproverByDate: 'claims/',
  getApprovedClaimsByEmpEmail: 'claims/',
  acceptClaimByLead: 'claims/',
  rejectClaimByLead: 'claims/',
  rejectClaimByfinanceApprover: 'claims/',
  acceptClaimByFinanceApprover: 'claims/',
  getCurrencyRates: 'currencies/',
  getExpClaimsFromNetSuite:
    'get-employee-expense-claims-by-email-from-netsuite/',
  getExpenseTypesByCountry: 'configurations/expense-types/',
  getJobNumbers: 'employees/',
  getEmployeeDetails: 'employees/',
  getGlCodes: 'get-gl-codes',
  getExpenseTypes: 'get-expense-types',
  getAllExpenseClaimsEndpoint: 'get-all-expense-claims/',
  // uploadReceipt: "receipts/file/",
  getProductUnitsAndBusinessUnits: 'configurations/products-and-business-units',
  getAllLeadEmails: 'employees/',

  // Credit Card Expenses endpoints
  // GET Endpoints
  // getCCTransactions
  // - Query Params:
  // - - dateTo - current date in yyyy-MM-dd format
  // - - dateFrom - past date to get transactions from in yyyy-MM-dd format
  // - - accessLevel - ['employee', 'lead', 'finance'] - Type of user logged in
  // - - email - email of the user
  getCCTransactions: 'transactions',
  // getCCAllCardData
  getCCAllCardData: 'credit-cards',
  // getCCInputMenuData
  // - Query Params:
  // - - email - email of the user
  getCCExpenseMenuData: 'configurations/expense-types',
  getCCSubRegionMenuData: 'configurations/sub-regions',
  getCCProductAndBusinessUnitMenuData:
    'configurations/product-and-business-units',
  getCCTravelJobNumberMenuData: 'travels/job-numbers',
  // getCCJobNumberData
  // - Path Params:
  // - - jobNumber - Job number code
  getCCJobNumberData: 'travels',
  // PATCH Endpoints
  // patchCCCardLabel
  // - Path Params:
  // - - id - ID of the credit card
  // - Body
  // - - Card details as a JSON Object (in CCCreditCard structure)
  patchCCCardLabel: 'credit-cards',
  // POST Endpoints
  // postCCBankStatement
  // - Query Params:
  // - - email - email of the user uploading the bank statement
  // - - bankCode - ['amex', 'svb'] - Bank name
  // - - statementFileName - (optional) File name of the statement
  // - Body
  // - - CSV bank statement file as a byte[]
  postCCBankStatement: 'transactions',
  // postCCTransactions
  // - Path Params:
  // - - (deprecated) putType - ['user-submit', 'lead-approve', 'finance-approve', 'edits-save'] - Type of updadte operation
  // - - updateType - ['save-draft', 'employee-submit', 'save-edit', 'lead-approve', 'finance-approve'] Type of updadte operation
  // - Body
  // - - Transactions as a JSON Object array (in CCSubmissionItem structure array)
  postCCTransactionsSaveDraft: 'transactions/save-draft',
  postCCTransactionsEmpSubmit: 'transactions/employee-submit',
  postCCTransactionsSaveEdit: 'transactions/save-edit',
  postCCTransactionsLeadApprove: 'transactions/lead-approve',
  postCCTransactionsFinanceApprove: 'transactions/finance-approve',

  // GET, POST and DELETE (dynamic) endpoint
  // fileAttchmentBase
  // - Path Params:
  // - - id - ID of the transaction
  fileAttachmentBase: 'transactions',
  // GET, PUT and DELETE (dynamic) endpoint
  // fileAttchmentResource
  // - Query Param:
  // - - attachmentType - ['receipt', 'contract'] - Type of attachment uploaded
  // - - fileExtenstion - Extension of the file (file type) (only for PUT endpoint)
  // - Body
  // - - Image/PDF file as a byte[]
  fileAttachmentResource: 'attachments',

  fileAttachment: 'claims/transactions/receipts/file/',
  fileAttachmentDelete: 'claims/transactions/receipts/file',

  //Bank Accounts Details endpoints
  insertNewBranchDetails: 'banks',
  insertNewBankDetails: 'banks',
  getBankDetails: 'banks',
  getBranchDetails: 'banks/',
  getAllBankDetails: 'banks',
  getAllAccountChanges: 'account-changes'
};

export const ClaimCategoryConfig = [
  {
    value: 'General',
    label: 'General'
  },
  {
    value: 'Spectacles',
    label: 'Spectacles'
  }
];

export const ExpensClaimCatgoryConfig = [
  {
    value: 'Travel',
    label: 'Travel'
  },
  {
    value: 'Spectacles',
    label: 'Spectacles'
  }
];

export const departmentsConfig = [
  {
    value: 'Engineering',
    lable: 'Engineering'
  },
  {
    value: 'Sales',
    lable: 'Sales'
  },
  {
    value: 'Marketing',
    lable: 'Marketing'
  },
  {
    value: 'HR',
    lable: 'HR'
  },
  {
    value: 'Legal',
    lable: 'Legal'
  }
];

export const currencyTypesByLocation = [
  {
    lable: 'Sri Lanka',
    value: 'LKR'
  },
  {
    lable: 'Australia',
    value: 'AUD'
  },
  {
    lable: 'Brazil',
    value: 'BRL'
  },
  {
    lable: 'Sri Lanka - TL',
    value: 'LKR'
  },
  {
    lable: 'Canada',
    value: 'CAD'
  },
  {
    lable: 'UK',
    value: 'GBP'
  },
  {
    lable: 'UK - TL',
    value: 'GBP'
  },
  {
    lable: 'US',
    value: 'USD'
  },
  {
    lable: 'US - VP',
    value: 'USD'
  },
  {
    lable: 'Spain',
    value: 'EUR'
  },
  {
    lable: 'Germany',
    value: 'EUR'
  },
  {
    lable: 'UAE',
    value: 'AED'
  },
  {
    lable: 'India',
    value: 'INR'
  },
  {
    lable: 'New Zealand',
    value: 'NZD'
  }
];

export const currencyTypesByCompany = [
  {
    lable: 'WSO2 - SRI LANKA',
    value: 'LKR'
  },
  {
    lable: 'WSO2-AUSTRALIA',
    value: 'AUD'
  },
  {
    lable: 'WSO2 - BRAZIL',
    value: 'BRL'
  },
  {
    lable: 'WSO2 - UK',
    value: 'GBP'
  },
  {
    lable: 'WSO2 - US',
    value: 'USD'
  },
  {
    lable: 'WSO2 - SPAIN',
    value: 'EUR'
  },
  {
    lable: 'WSO2 - CANADA',
    value: 'CAD'
  },
  {
    lable: 'WSO2 Germany GmbH',
    value: 'EUR'
  },
  {
    lable: 'WSO2 - UAE',
    value: 'AED'
  },
  {
    lable: 'WSO2- INDIA',
    value: 'INR'
  },
  {
    lable: 'WSO2 - NEW ZEALAND',
    value: 'NZD'
  }
];

export const CCConfig = {
  SUBMIT_TYPE_USER: 'user-submit',
  SUBMIT_TYPE_LEAD: 'lead-approve',
  SUBMIT_TYPE_FINANCE: 'finance-approve',
  SUBMIT_TYPE_SAVE_EDITS: 'edits-save',
  SUBMIT_TYPE_SAVE_DRAFT: 'temp-save'
};

export const APP_NAME = window.config?.REACT_APP_APP_NAME ?? '';
export const APP_DESCRIPTION =
  'Manage your OPD claims, credit card transactions and expense claims';

export const FINANCE_ROLE = window.config?.REACT_APP_FINANCE_ROLE ?? '';
export const ADMIN_ROLE = window.config?.REACT_APP_ADMIN_ROLE;

export const AUTH_CONFIG = {
  signInRedirectURL: window.config?.REACT_APP_SIGN_IN_REDIRECT_URL ?? '',
  signOutRedirectURL: window.config?.REACT_APP_SIGN_OUT_REDIRECT_URL ?? '',
  baseUrl: window.config?.REACT_APP_ASGARDEO_BASE_URL ?? '',
  clientID: window.config?.REACT_APP_ASGARDEO_CLIENT_ID ?? '',
  scope: window.config?.REACT_APP_ASGARDEO_SCOPES.split(' ') ?? []
};
