import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography } from "@material-ui/core";

const styles = theme => ({
  loader: {
    marginTop: 10,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center"
  },
  loaderText: {
    marginTop: "3%"
  }
});

// Displays a simple loader with customizable message

class Loader extends Component {
  render() {
    const { classes } = this.props;
    const { message } = this.props;

    return (
      <div className={classes.loader}>
        <CircularProgress />
        <Typography variant="h6" className={classes.loaderText}>
          {message}
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(Loader);
